import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { twMerge } from 'tailwind-merge'

import { api } from '../services/api.service'

import ImageViewer from '../components/image-viewer'
import Loading from '../components/loading'

import BuyProductForm from '../forms/buy-product-form'
import FormModal from '../forms/form-modal'

import { style } from '../style'

export default function ListingDetail(props) {
    const [data, setData] = useState()
    const [showBuyModal, setShowBuyModal] = useState(false)
    const [submitted, setSubmitted] = useState(false)
    const navigate = useNavigate()
    const params = useParams()
    let id = params.id ?? props.id

    useEffect(() => {
        api(`${process.env.REACT_APP_API_URL}/app/full-listing/${id}`).then((x) => {
            setData(x)
        })
    }, [])

    if (!data) return <Loading></Loading>

    return (
        <div className='p-4 m-4 bg-white rounded shadow'>
            <FormModal open={showBuyModal} setOpen={setShowBuyModal}>
                <div className='w-full max-w-6xl p-5 bg-white rounded shadow'>
                    <BuyProductForm
                        id={id}
                        submitted={() => {
                            setSubmitted(true)
                            setShowBuyModal(false)
                        }}
                    />
                </div>
            </FormModal>
            <FormModal open={submitted} setOpen={setSubmitted}>
                <div className='w-full max-w-6xl p-5 bg-white rounded shadow'>
                    <h2 className='text-lg font-semibold'>Message sent!</h2>
                    <button
                        className={twMerge(style.button.classname, style.button._secondary.classname, 'mt-6')}
                        type='Button'
                        onClick={() => {
                            return navigate('/marketplace')
                        }}
                    >
                        Return to marketplace
                    </button>
                </div>
            </FormModal>
            <div className='px-4 sm:px-0'>
                <h3 className='text-base font-semibold leading-7 text-gray-900'>
                    Listing Information<span className={`px-3 py-1 ml-2 font-medium text-center rounded-md ring-1 ring-inset ${data.ownership.isCustom ? 'text-brand-700 ring-brand-600/20 bg-brand-50' : 'text-blue-700 ring-blue-600/20 bg-blue-50'}`}>{data.ownership.isCustom ? 'Custom' : 'Verified'}</span>
                </h3>
            </div>
            <div className='mt-6 border-t border-gray-100'>
                <dl className='divide-y divide-gray-100'>
                    <div className='px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0'>
                        <dt className='text-sm font-medium leading-6 text-gray-900'>Name</dt>
                        <dd className='mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0'>{data.ownership.product ? data.ownership.product.name : data.ownership.name}</dd>
                    </div>
                    <div className='px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0'>
                        <dt className='text-sm font-medium leading-6 text-gray-900'>Price</dt>
                        <dd className='mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0'>£{data.price}</dd>
                    </div>
                    <div className='px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0'>
                        <dt className='text-sm font-medium leading-6 text-gray-900'>Community</dt>
                        <dd className='mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0'>{data.organisation.name}</dd>
                    </div>
                    <div className='px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0'>
                        <dt className='text-sm font-medium leading-6 text-gray-900'>Description</dt>
                        <dd className='mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0'>{data.notes}</dd>
                    </div>
                    <div className='px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0'>
                        <dt className='text-sm font-medium leading-6 text-gray-900'>Picture</dt>
                        <dd className='mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0'>
                            {data.ownership.photo && (
                                <div>
                                    <ImageViewer className='object-cover w-auto h-64' image={data.ownership.photo} />
                                </div>
                            )}
                            {data.ownership.product?.photo && (
                                <div>
                                    <ImageViewer className='h-64' image={data.ownership.product?.photo} />
                                </div>
                            )}
                        </dd>
                    </div>
                    <div className='mt-2 text-center'>
                        <button onClick={() => setShowBuyModal(true)} className={twMerge(style.button.classname, style.button._primary.classname, `px-10 py-2 mx-auto mt-4`)}>
                            Contact seller
                        </button>
                    </div>
                </dl>
            </div>
        </div>
    )
}
