import { useEffect, useState } from 'react'
import { twMerge } from 'tailwind-merge'

import { api } from '../services/api.service'

import ImageViewer from '../components/image-viewer'
import Loading from '../components/loading'

import { SelectField, TextField } from '../forms/fields'

import { style } from '../style'

export default function Marketplace() {
    const [data, setData] = useState()
    const [orgFilter, setOrgFilter] = useState('')
    const [displayData, setDisplayData] = useState()
    const [userData, setUserData] = useState()
    const [memberships, setMemberships] = useState()
    const [searchValue, setSearchValue] = useState('')

    useEffect(() => {
        api(`${process.env.REACT_APP_API_URL}/app/marketplace`).then((x) => {
            setData(x)
            setDisplayData(x.relevantListings)
            setUserData(x.userListings)
        })
        api(`${process.env.REACT_APP_API_URL}/app/user-memberships`).then((x) => {
            setMemberships(x.memberships)
        })
    }, [])

    const handleSearch = () => {
        let searchTerm = new RegExp(searchValue, 'i')
        const sortedData = data.relevantListings.filter(x => !!x.ownership).filter((item) => {
            if (searchTerm.test(item.ownership.name + item.ownership.product?.name)) {
                return true
            }
            return false
        })
        setDisplayData(sortedData)
    }

    if (!displayData) return <Loading></Loading>

    return (
        <>
            <div className='mt-10 text-3xl font-semibold text-neutral-800'>Marketplace</div>
            <div className='mt-5'>
                <div className='max-w-2xl py-10 mx-auto lg:max-w-7xl'>
                    <h2 className='text-xl font-bold tracking-tight text-gray-900'>Your Listings</h2>
                    <div className='grid grid-cols-1 mt-2 mb-10 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8'>
                        {userData.filter(x => !!x.ownership).length === 0 && <div className='col-span-1 sm:col-span-2 lg:col-span-4'>You currently have no active listings</div>}
                        {userData.filter(x => !!x.ownership).map((product) => (
                            <div key={product._id} className='relative group'>
                                <div className='w-full overflow-hidden bg-white rounded-md group-hover:opacity-75'>
                                    <ImageViewer image={product.ownership.photo || product.ownership.product?.photo} alt={product.imageAlt} className='object-cover object-center w-full h-full lg:h-full lg:w-full' />
                                </div>
                                {product.sold_at && <div className='absolute block w-full text-lg font-bold text-red-500 top-10 left-10'>Sold: {product.sold_at.split('T')[0].split('-').reverse().join('/')}</div>}
                                <div className='flex justify-between mt-4'>
                                    <div>
                                        <h3 className='text-sm text-gray-700'>
                                            <a href={`/edit-listing/${product._id}`}>
                                                <span aria-hidden='true' className='absolute inset-0' />
                                                {product.ownership.isCustom ? product.ownership?.name : product.ownership.product?.name}
                                            </a>
                                        </h3>
                                        <p className='mt-1 text-sm text-gray-500'>{product.organisation?.name}</p>
                                    </div>
                                    <p className='text-sm font-medium text-gray-900'>£{product.price}</p>
                                </div>
                            </div>
                        ))}
                    </div>

                    <h2 className='text-xl font-bold tracking-tight text-gray-900'>Search</h2>
                    <div className='flex flex-col w-full my-2 sm:space-x-10 sm:flex-row'>
                        <div className='flex flex-1'>
                            <SelectField
                                label='Select Community'
                                placeholder='Select...'
                                className='flex-1'
                                value={orgFilter}
                                options={memberships
                                    .filter((x) => x.status === 'approved')
                                    .map((x) => {
                                        return {
                                            text: x.organisation?.name,
                                            value: x.organisation._id,
                                        }
                                    })}
                                onChange={(val) => {
                                    setOrgFilter(val)
                                }}
                            />
                            <button onClick={() => setOrgFilter('')} className={twMerge(style.button.classname, style.button._primary.classname, 'ml-2 self-end w-24 shadow flex-0')}>
                                Reset
                            </button>
                        </div>
                        <div className='flex flex-1'>
                            <TextField className='flex-1' label='Search Items' placeholder='Start typing...' value={searchValue} onChange={(val) => setSearchValue(val)} />
                            <button onClick={handleSearch} className={twMerge(style.button.classname, style.button._secondary.classname, ' ml-2 self-end w-24 shadow flex-0')}>
                                Search
                            </button>
                        </div>

                    </div>
                    <div className='grid grid-cols-1 mt-6 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8'>
                        {displayData.length === 0 && <div>There are no items available to view</div>}
                        {!orgFilter &&
                            displayData.filter(x => !!x.ownership).map((product) => (
                                <div key={product._id} className={`relative group ${product.sold_at ? 'border-2 border-red-500' : ''}`}>
                                    <div className='w-full overflow-hidden bg-white rounded-md aspect-h-1 aspect-w-1 group-hover:opacity-75 '>
                                        <ImageViewer image={product.ownership?.photo || product.ownership?.product?.photo} alt={product.imageAlt} className='object-cover w-full' />
                                    </div>
                                    <div className='flex justify-between mt-4'>
                                        <div>
                                            <h3 className='text-sm text-gray-700'>
                                                <a href={`/listing/${product._id}`}>
                                                    <span aria-hidden='true' className='absolute inset-0' />
                                                    {product.ownership.isCustom ? product.ownership?.name : product.ownership.product?.name}
                                                </a>
                                            </h3>
                                            <p className='mt-1 text-sm text-gray-500'>{product.organisation?.name}</p>
                                            <p className='mt-1 text-sm text-gray-500'>{product.seller?.name}</p>

                                        </div>
                                        <p className='ml-3 text-sm font-medium text-gray-900'>£{product.price}</p>
                                    </div>
                                </div>
                            ))}
                        {orgFilter &&
                            displayData.filter(x => !!x.ownership)
                                .filter((product) => product.organisation?._id === orgFilter)
                                .map((product) => (
                                    <div key={product._id} className={`relative group ${product.sold_at ? 'border-2 border-red-500' : ''}`}>
                                    <div className='w-full overflow-hidden bg-white rounded-md aspect-h-1 aspect-w-1 group-hover:opacity-75 '>
                                            <ImageViewer image={product.ownership?.photo || product.ownership?.product?.photo} alt={product.imageAlt} className='object-cover object-center w-full h-full lg:h-full lg:w-full' />
                                        </div>
                                        <div className='flex justify-between mt-4'>
                                            <div>
                                                <h3 className='text-sm text-gray-700'>
                                                    <a href={`/listing/${product._id}`}>
                                                        <span aria-hidden='true' className='absolute inset-0' />
                                                        {product.ownership?.isCustom ? product.ownership?.name : product.ownership?.product?.name}
                                                    </a>
                                                </h3>
                                                <p className='mt-1 text-sm text-gray-500'>{product.organisation?.name}</p>
                                                <p className='mt-1 text-sm text-gray-500'>{product.seller?.name}</p>
                                            </div>
                                            <p className='ml-3 text-sm font-medium text-gray-900'>£{product.price}</p>
                                        </div>
                                    </div>
                                ))}
                        {orgFilter && displayData.filter((product) => product.organisation?._id === orgFilter).length === 0 && <h2 className='col-span-1 sm:col-span-2 lg:col-span-4'>There are no items listed in the selected organisation.</h2>}
                    </div>
                </div>
            </div>
        </>
    )
}
