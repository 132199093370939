import 'react-toastify/dist/ReactToastify.css'

import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { twMerge } from 'tailwind-merge'

import { api, apiNoAuth } from '../services/api.service'

import ImageViewer from '../components/image-viewer'
import Loading from '../components/loading'

import FormModal from '../forms/form-modal'

import { style } from '../style'

export default function JoinOrganisation(props) {
    const [data, setData] = useState(null)
    const [userType, setUserType] = useState()
    const [membership, setMembership] = useState(null)
    const [showModal, setShowModal] = useState(false)
    const navigate = useNavigate()
    const params = useParams()
    let id = params.id ?? props.id

    useEffect(() => {
        apiNoAuth(`${process.env.REACT_APP_API_URL}/app/check-user-type`).then((x) => setUserType(x.userType))
    }, [])

    useEffect(() => {
        if (userType && userType !== 'anonymous') {
            api(`${process.env.REACT_APP_API_URL}/app/membership/${id}`).then((x) => {
                setMembership(x)
            })
        }
    }, [userType])

    useEffect(() => {
        if (!membership) {
            api(`${process.env.REACT_APP_API_URL}/public/organisation/${id}`).then((x) => {
                setData(x)
            })
        }
    }, [membership])

    if (!data) return <Loading></Loading>

    return (
        <div className='max-w-lg mx-auto mt-10 overflow-hidden bg-white shadow sm:rounded-lg'>
            <FormModal open={showModal} setOpen={setShowModal}>
                <div className='w-full max-w-3xl p-5 bg-white rounded-md shadow'>
                    <h2>Community request submitted.</h2>
                    <p>Sit tight, awaiting approval from the admin!</p>
                    <button
                        onClick={() => {
                            setShowModal(false)
                            navigate('/')
                        }}
                        className={twMerge(style.button.classname, style.button._primary.classname)}
                    >
                        Go to homepage
                    </button>
                </div>
            </FormModal>
            <div className='flex px-4 py-6 sm:px-6'>
                {<ImageViewer className='inline-block mr-4 rounded-md h-14 w-14' image={data.image} />}
                <div>
                    <h3 className='text-base font-semibold leading-7 text-gray-900'>{data.name}</h3>
                </div>
            </div>
            <div className='border-t border-gray-100'>
                <dl className='divide-y divide-gray-100'>
                    <div className='px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
                        <dt className='text-sm font-medium text-gray-900'>Description</dt>
                        <dd className='mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0'>{data.description}</dd>
                    </div>
                    <div className='flex flex-col items-center px-4 py-6 sm:px-6 '>
                        {userType !== 'anonymous' && !membership && (
                            <button
                                className={twMerge(style.button.classname, style.button._secondary.classname)}
                                onClick={() => {
                                    api(`${process.env.REACT_APP_API_URL}/app/request-membership/${id}`, {})
                                    setShowModal(true)
                                }}
                            >
                                Request to join
                            </button>
                        )}
                        {membership && membership.status === 'pending' && <p className='text-gray-700'>Membership is pending approval</p>}
                        {membership && membership.status === 'approved' && <p className='text-gray-700'>You are a member of this community</p>}
                        {userType !== 'anonymous' && membership && membership.status === 'rejected' && (
                            <>
                                <p className='text-gray-700'>Membership was rejected.</p>
                                <button className={twMerge(style.button.classname, style.button._primary.classname)} onClick={() => navigate('/')}>
                                    Return to homepage
                                </button>
                            </>
                        )}
                        {userType === 'anonymous' && (
                            <>
                                <p className='text-gray-700'>You must be logged in to join organisations.</p>
                                <button className={twMerge(style.button.classname, style.button._primary.classname, 'w-48')} onClick={() => navigate('/register?rd=%2Fjoin-organisation%2F' + id)}>
                                    Register and Join
                                </button>
                                <button className={twMerge(style.button.classname, style.button._primary.classname, 'w-48')} onClick={() => navigate('/login?rd=%2Fjoin-organisation%2F' + id)}>
                                    Login and Join
                                </button>
                            </>
                        )}
                    </div>
                </dl>
            </div>
        </div>
    )
}
