import 'react-toastify/dist/ReactToastify.css';

import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import Button from '../components/button';
import ImageViewer from '../components/image-viewer';
import { api } from '../services/api.service';
export default function Communities(props) {
    const params = useParams()
    const navigate = useNavigate();
    const [communities, setCommunities] = useState();
    const [memberships, setMemberships] = useState()

    useEffect(() => {
        api(`${process.env.REACT_APP_API_URL}/app/communities`).then((c) => {
            console.log(c)
            setCommunities(c)
        })
        api(`${process.env.REACT_APP_API_URL}/app/user-memberships`).then((x) => setMemberships(x))
    }, [])

    return (
        <div className=''>
            <div className="mt-10 mb-5 text-3xl font-semibold text-neutral-800">Communities</div>
            <div className="grid gap-5 sm:grid-cols-2">
                {communities?.map(x => <div className="p-5 transition-all rounded shadow cursor-pointer bg-white/80 hover:shadow-xl hover:scale-105" onClick={() => navigate('/join-organisation/' + x._id)}>

                    <div className="flex items-center space-x-5">
                        <ImageViewer className='object-cover w-24 rounded flex-0' image={x.image} />

                        <div>
                            <div className="mb-2 text-xl text-neutral-800">{x.name}</div>
                            <div className="text-neutral-600">{x.description}</div>
                        </div>
                    </div>



                </div>)}


            </div>
            <div className="flex items-center justify-center m-5">
                <Button onClick={() => navigate('/request-community')}>Create a community</Button>
            </div>

        </div>
    )
}
