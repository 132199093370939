export default function TextAreaField({ label, value, onChange, className, inputClass, labelClass, onBlur, placeholder, maxLength }) {
    return (
        <div className={className + ' mt-2'}>
            <label className={`block text-sm font-medium leading-6 text-neutral-600 ${labelClass}`}>{label}</label>
            <div className='mt-1'>
                <textarea placeholder={placeholder} onBlur={onBlur} value={value} onChange={(e) => onChange(e.target.value)} rows={4} maxLength={maxLength} className={`block bg-slate-100 w-full rounded border-0 text-neutral-700 shadow-sm ring-1 ring-inset ring-gray-200 placeholder:text-gray-400 outline-none focus:ring-inset focus:ring-1 focus:ring-gray-200 sm:py-1.5 sm:text-sm sm:leading-6 ${inputClass}`} />
            </div>
        </div>
    )
}
