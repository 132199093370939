import { useState } from 'react'
import { twMerge } from 'tailwind-merge'

import { api } from '../services/api.service'

import { style } from '../style'
import { TextAreaField, TextField } from './fields'

export default function BuyProductForm(props) {
    const [values, setValues] = useState({})
    const [error, setError] = useState(null)

    const handleSubmit = () => {
        if (!values.contact) {
            return setError('You must include a way for the buyer to contact you.')
        }
        if (!values.message) {
            return setError('Please leave a message for the seller.')
        }
        api(`${process.env.REACT_APP_API_URL}/app/buy/${props.id}`, values)
        props.submitted()
    }
    return (
        <div className='text-left w-96'>
            <TextAreaField required label='Message to Seller' value={values.message} onChange={(val) => setValues({ ...values, message: val })}></TextAreaField>
            <TextField required label='Your contact details' placeholder='Mobile / email for the seller to reply to you' value={values.contact} onChange={(val) => setValues({ ...values, contact: val })} />
            {error && <div className='text-red-600'>{error}</div>}
            <button className={twMerge(style.button.classname, style.button._secondary.classname, 'block mx-auto mt-4')} onClick={handleSubmit} type='button'>
                Send
            </button>
        </div>
    )
}
