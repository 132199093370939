import 'react-toastify/dist/ReactToastify.css'

import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { ToastContainer } from 'react-toastify'
import { twMerge } from 'tailwind-merge'

import { api, apiNoAuth } from '../services/api.service'

import AuxiliaryOwnershipDetails from '../components/auxiliary-product-details'
import Card from '../components/card'
import ImageViewer from '../components/image-viewer'
import Loading from '../components/loading'

import FindProductForm from '../forms/find-product-form'
import FormModal from '../forms/form-modal'
import LostProductForm from '../forms/lost-product-form'

import RehomeConfirm from '../components/rehome-confirm'
import { style } from '../style'

export default function OwnershipDetails(props) {
    const [data, setData] = useState(null)
    const [userType, setUserType] = useState(null)
    const [user, setUser] = useState(null)
    const [showFindModal, setShowFindModal] = useState(false)
    const [showLostModal, setShowLostModal] = useState(false)
    const [showLostModalForFoundItem, setShowLostModalForFoundItem] = useState(false)
    const navigate = useNavigate()
    const [submitted, setSubmitted] = useState(false)
    const params = useParams()
    const [confirmDisown, setConfirmDisown] = useState(false)
    const [recentOwnership, setRecentOwnership] = useState(false)

    let id = params.id ?? props.id

    useEffect(() => {

        apiNoAuth(`${process.env.REACT_APP_API_URL}/app/check-user-type`).then((x) => setUserType(x.userType))
    }, [])

    useEffect(() => {
        if (userType === 'merchant' || userType === 'user') {
            api(`${process.env.REACT_APP_API_URL}/app/get-current-user-details`).then((x) => {
                setUser(x.user)
            })
            api(`${process.env.REACT_APP_API_URL}/public/ownership/${id}`).then((x) => {
                let dataObj = x
                api(`${process.env.REACT_APP_API_URL}/app/foundEvents/${id}`).then((y) => {
                    dataObj = { ...dataObj, foundEvent: y.foundEvent, foundEvents: y.foundEvents }
                    setData(dataObj)
                })
            })
        }
        else {
            api(`${process.env.REACT_APP_API_URL}/public/ownership/${id}`).then((x) => {
                setData(x)
            })
        }
    }, [userType])

    const markAsReturned = () => {
        api(`${process.env.REACT_APP_API_URL}/app/mark-as-returned/${id}`).then((x) => {
            api(`${process.env.REACT_APP_API_URL}/public/ownership/${id}`).then((x) => setData(x))
        })
    }
    const claimItem = () => {
        api(`${process.env.REACT_APP_API_URL}/app/claim-product/${id}`).then((x) => {
            setRecentOwnership(true)
            api(`${process.env.REACT_APP_API_URL}/public/ownership/${id}`).then((x) => setData(x))
        })
    }

    const disown = (id) => {
        api(`${process.env.REACT_APP_API_URL}/app/disown-product/${id}`).then((x) => {
            api(`${process.env.REACT_APP_API_URL}/public/ownership/${id}`).then((x) => setData(x))
        })
    }

    const getProductName = (item) => {
        if (!item.product && !item.name) {
            return 'Custom Item'
        } else if (!item.product) {
            return item.name
        } else return item.product?.name
    }

    const HandleButtonFlow = () => {
        if (user) {
            if (data.ownership.currentOwner) {
                if (data.ownership.currentOwner === user._id) {
                    if (data.ownership.isLost) {
                        return (
                            <>
                                <button className={twMerge(style.button.classname, style.button._secondary.classname)} onClick={() => markAsReturned()}>
                                    Returned
                                </button>
                                <button
                                    className={twMerge(style.button.classname, style.button._dark.classname)}
                                    onClick={() => {
                                        setConfirmDisown(id)
                                    }}
                                >
                                    Rehome
                                </button>
                                {data.ownership.isCustom && (
                                    <button
                                        className={twMerge(style.button.classname, 'bg-light')}
                                        onClick={() => {
                                            navigate(`/create-ownership/${id}`)
                                        }}
                                    >
                                        Edit
                                    </button>
                                )}
                            </>
                        )
                    }
                    else if (data.ownership.isFound) {
                        return (
                            <>
                                <button className={twMerge(style.button.classname, style.button._primary.classname)} onClick={() => markAsReturned()}>
                                    Returned
                                </button>
                                <button
                                    className={twMerge(style.button.classname, style.button._dark.classname)}
                                    onClick={() => {
                                        setConfirmDisown(id)
                                    }}
                                >
                                    Rehome
                                </button>
                                {data.ownership.isCustom && (
                                    <button
                                        className={twMerge(style.button.classname, 'bg-light')}
                                        onClick={() => {
                                            navigate(`/create-ownership/${id}`)
                                        }}
                                    >
                                        Edit
                                    </button>
                                )}
                            </>
                        )
                    }
                    else {
                        return (
                            <>
                                <button className={twMerge(style.button.classname, style.button._secondary.classname)} onClick={() => setShowLostModal(true)}>
                                    Lost
                                </button>
                                <button className={twMerge(style.button.classname, style.button._primary.classname)} onClick={() => navigate(`/create-listing/${data.ownership._id}`)}>
                                    List
                                </button>
                                <button
                                    className={twMerge(style.button.classname, style.button._dark.classname)}
                                    onClick={() => {
                                        setConfirmDisown(id)
                                    }}
                                >
                                    Rehome
                                </button>
                                {data.ownership.isCustom && (
                                    <button
                                        className={twMerge(style.button.classname, 'bg-light')}
                                        onClick={() => {
                                            navigate(`/create-ownership/${id}`)
                                        }}
                                    >
                                        Edit
                                    </button>
                                )}
                            </>
                        )
                    }
                } else {

                    if (!data.ownership.isLost) {
                        return (
                            <button className={twMerge(style.button.classname, style.button._secondary.classname)} onClick={() => setShowFindModal(true)}>
                                Found Me?
                            </button>
                        )
                    }


                }
            } else if (data.ownership.isCustom) {
                return (
                    <button className={twMerge(style.button.classname, style.button._secondary.classname)} onClick={() => navigate(`/create-ownership/${id}`)}>
                        Claim Item
                    </button>
                )
            } else {
                return (
                    <button className={twMerge(style.button.classname, style.button._secondary.classname)} onClick={() => claimItem()}>
                        Claim Item
                    </button>
                )
            }
        } else {
            if (data.ownership.currentOwner) {
                if (!data.ownership.isLost) {
                    return (
                        <button className={twMerge(style.button.classname, style.button._secondary.classname)} onClick={() => setShowFindModal(true)}>
                            Found Me?
                        </button>
                    )
                }
            } else {
                return (
                    <>
                        <button className={twMerge(style.button.classname, style.button._primary.classname)} onClick={() => navigate('/register?rd=%2Fownership%2F' + id)}>
                            Register And Tag
                        </button>
                        <button className={twMerge(style.button.classname, style.button._secondary.classname)} onClick={() => navigate('/login?rd=%2Fownership%2F' + id)}>
                            Login And Tag
                        </button>
                    </>
                )
            }
        }
    }

    if (!data) return <Loading></Loading>

    return (
        <div>
            <RehomeConfirm
                open={!!confirmDisown}
                confirm={() => {
                    disown(confirmDisown)
                    setConfirmDisown(false)
                }}
                cancel={() => {
                    setConfirmDisown(false)
                }}
            />

            <FormModal open={showFindModal} setOpen={setShowFindModal}>
                <div className='w-full max-w-xl p-5 bg-white rounded-md shadow'>
                    <div className='w-full text-center'>Thanks for helping return this item with Boomerang.</div>

                    <FindProductForm
                        submitted={() => {
                            setSubmitted(true)
                            setShowFindModal(false)
                        }}
                        id={id}
                    />
                </div>
            </FormModal>
            <FormModal open={showLostModalForFoundItem} setOpen={setShowLostModalForFoundItem}>
                <div className='w-full max-w-xl p-5 text-sm bg-white rounded-md shadow sm:text-base'>
                    <div className='mb-2 text-neutral-800'>Hooray! Someone has found your item.</div>
                    <div className='mb-2 text-neutral-800'>To get your much loved belonging back as fast as possible, we suggest leaving your contact number and name.</div>
                    <div className='mb-2 text-neutral-800'>If you don't leave any details, that's also OK, the finder will still be able to send an anonymous email to you with return details.</div>
                    <div className='mb-2 text-neutral-800'>Our fingers and toes are crossed for you!</div>
                    <div className='mb-2 text-neutral-800'>p.s. be sure to check your junk mail we wouldn't want it to end up in there!</div>
                    <LostProductForm
                        submitted={() => {
                            api(`${process.env.REACT_APP_API_URL}/public/ownership/${id}`).then((x) => setData(x))
                            setShowLostModalForFoundItem(false)
                        }}
                        id={id}
                    />
                </div>
            </FormModal>
            <FormModal open={showLostModal} setOpen={setShowLostModal}>
                <div className='w-full max-w-xl p-5 text-sm bg-white rounded-md shadow sm:text-base'>
                    <div className='mb-2 text-neutral-800'>Uh oh! You've lost it.</div>
                    <div className='mb-2 text-neutral-800'>Don't worry too much though, help is just around the corner. We feel it!</div>
                    <div className='mb-2 text-neutral-800'>Just provide some contact details such as your phone number and name for the finder here.</div>
                    <div className='mb-2 text-neutral-800'>If you don't leave any details, that's also OK, the finder will still be able to send an anonymous email to you with return details.</div>
                    <div className='mb-2 text-neutral-800'>Our fingers and toes are crossed for you!</div>
                    <div className='mb-2 text-neutral-800'>p.s. make sure Boomerang is an authorised sender, we wouldn't want it to end up in your junk mail.</div>
                    <LostProductForm
                        submitted={() => {
                            api(`${process.env.REACT_APP_API_URL}/public/ownership/${id}`).then((x) => setData(x))
                            setShowLostModal(false)
                        }}
                        id={id}
                    />
                </div>
            </FormModal>
            <Card className='p-5 mt-10'>
                <ToastContainer autoClose={2000} position='bottom-right' />

                <div className='flex flex-col justify-evenly sm:space-x-8 sm:flex-row'>
                    <div className='shrink-0'>
                        <div className='text-sm text-neutral-600'> {data.ownership?.product?.brand || ''}</div>
                        <div className='mb-5 text-3xl font-black'>{getProductName(data.ownership)}</div>
                        {(data.ownership.photo || data.ownership.product?.photo) && <ImageViewer className='object-cover w-64 h-64 rounded-md shadow' image={data.ownership.product?.photo || data.ownership.photo} />}
                        {!data.ownership.isCustom && (data.ownership.size || data.ownership.category || data.ownership.colour || data.ownership.style || data.ownership.additionalDetails) && <AuxiliaryOwnershipDetails product={data.ownership.product} />}

                        <div className='flex flex-col items-start mt-10 space-y-3'>{<HandleButtonFlow />}</div>
                    </div>
                    <div className='mt-5 text-left'>
                        <div className=' text-neutral-700'>
                            {!data.ownership.currentOwner && (
                                <div>
                                    <div className='text-xl font-medium text-neutral-800'>Welcome to Boomerang!</div>
                                    <div className='mt-1 mb-3 text-lg'>Nametags just got smart.</div>
                                    <div className='mt-1 mb-3 text-lg'>Say hi to BoomerangTag.</div>
                                    {userType !== 'anonymous' && <div className='mt-1 mb-3 text-lg'>Never lose this {data.ownership?.isCustom ? 'item' : getProductName(data.ownership)} again.</div>}
                                    {userType === 'anonymous' && <div className='mt-1 mb-3 text-lg'>Never lose this {data.ownership?.isCustom ? 'item' : getProductName(data.ownership)} again. Simply register and claim it!</div>}
                                </div>
                            )}
                            {data.ownership.currentOwner && data.ownership.currentOwner !== user?._id && !data.ownership.isLost && (
                                <div>
                                    <div className='text-xl font-medium text-neutral-800'>Welcome to Boomerang!</div>
                                    <div className='mt-1 mb-3 text-lg'>Nametags just got smart.</div>
                                    <div className='mt-1 mb-3 text-lg'>We're on a mission to save 100,000 items to reduce the giant pile of global lost property and help people reunite with their most loved belongings.</div>
                                    <div className='mt-1 mb-3 text-lg'>Have you found this item? Amazing!</div>
                                    <div className='mt-1 mb-3 text-lg'>Tap "Found me?” and leave contact details so we can send details of how to return the item to its owner who will be very relieved!</div>
                                </div>
                            )}
                            {data.ownership.currentOwner !== user?._id && data.ownership.isLost && (
                                <div>
                                    <div className='text-xl font-medium text-neutral-800'>Welcome to Boomerang!</div>
                                    <div className='mt-1 mb-3 text-lg'>Thanks for finding this item. You are a HERO!</div>
                                    <div className='mt-1 mb-3 text-lg'>The owner has left these details to return their item:</div>

                                    <div className='mt-1 mb-3 text-lg font-semibold text-brand'>{data.ownership.returnInstructions}</div>
                                    <div className='mt-1 mb-3 text-lg'>You can also tap contact owner to get in touch with them. They will be notified anonymously by email with your note with return details to them.</div>
                                    <div className='mt-1 mb-3 text-lg'>Thanks for being a hero.
                                    </div>
                                    <button
                                        className={twMerge(style.button.classname, style.button._secondary.classname)}
                                        onClick={() => {
                                            setShowFindModal(true)
                                        }}
                                    >
                                        Contact Owner
                                    </button>
                                </div>
                            )}
                            {!data.ownership.isLost && !data.ownership.isFound && data.ownership.currentOwner && data.ownership.currentOwner === user?._id && (
                                <div>
                                    {recentOwnership && (
                                        <>
                                            <div className='text-xl font-medium text-neutral-800'>Hooray! You own this item</div>
                                            <div className='mt-1 mb-3 text-lg'>We hope you will love this item like one of your own.</div>
                                            <div className='mt-1 mb-3 text-lg'>If you lose it - and we really hope you don't - mark "Lost" to help the finder return the item.</div>
                                            <div className='mt-1 mb-3 text-lg'>If the finder finds it first they will leave a note for you and an email to notify you.</div>
                                            <div className='mt-1 mb-3 text-lg'>Tap "List" to list for free on our Community Marketplace.</div>
                                            <div className='mt-1 mb-3 text-lg'>If you want to give the item to someone else, tap "Rehome" so the new owner can claim it.</div>
                                        </>
                                    )}
                                    {!recentOwnership && (
                                        <>
                                            <div className='text-xl font-medium text-neutral-800'>Hooray! You own this item</div>
                                            <div className='mt-1 mb-3 text-lg'>We hope you will love this item like one of your own.</div>
                                            <div className='mt-1 mb-3 text-lg'>If you lose it - and we really hope you don't - mark "Lost" to help the finder return the item.</div>
                                            <div className='mt-1 mb-3 text-lg'>If the finder finds it first they will leave a note for you and an email to notify you.</div>
                                            <div className='mt-1 mb-3 text-lg'>Tap "List" to list for free on our Community Marketplace.</div>
                                            <div className='mt-1 mb-3 text-lg'>If you want to give the item to someone else, tap "Rehome" so the new owner can claim it.</div></>
                                    )}
                                </div>
                            )}
                            {submitted && <div className='text-center text-neutral-700'>Thanks for helping return this item!</div>}
                            {/* Item found */}
                            {data.ownership.isFound && user && data.ownership.currentOwner === user?._id && (
                                <>
                                    <div className='text-xl font-medium'>Hooray! Your item has been found.
                                    </div>
                                    <div className='mt-1 text-neutral-700'>Here's a message from the hero who found it:</div>
                                    {data.allFoundEvents?.length > 1 && <div>
                                        {data.allFoundEvents.map(x => <div className='mt-3 font-medium text-neutral-800'>{x?.note}</div>)}
                                    </div>}
                                    {!(data.allFoundEvents?.length > 1) && <div>
                                        <div className='mt-3 font-medium text-neutral-800'>{data.foundEvent?.note}</div>
                                    </div>}
                                    <button
                                        className={twMerge(style.button.classname, style.button._secondary.classname)}
                                        onClick={() => {
                                            setShowLostModalForFoundItem(true)
                                        }}
                                    >
                                        Message Finder
                                    </button>
                                </>
                            )}
                            {/* Item is lost */}
                            {data.ownership.currentOwner === user?._id && data.ownership.isLost && !data.ownership.isFound && (
                                <>
                                    <div className='mt-1 mb-3 text-lg'>Oh no! This item is lost. But fear not, Boomerang help is on the way.</div>
                                    <div className='mt-1 mb-3 text-lg'>If someone finds it, they will contact you with the details you left to return the item and we'll email you as well.</div>
                                    <div className='mt-1 mb-3 text-lg'>Make sure Boomerang is a recognised emailer so this good news doesn't end up in your junk folder!</div>
                                    <div className='mt-1 mb-3 text-lg'>Our fingers and toes are crossed for you and your beloved item.</div>

                                    {data.ownership.returnInstructions && (
                                        <>
                                            <div className='mt-10 text-xs text-neutral-400'>Details you have left for the finder:</div>
                                            <div className='my-2 text-lg font-medium'>{data.ownership.returnInstructions}</div>
                                        </>
                                    )}
                                    {!data.ownership.returnInstructions && (
                                        <>
                                            <div className='my-2 text-lg font-medium'>You have not left any details for the finder</div>
                                        </>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </Card>
        </div>
    )
}
