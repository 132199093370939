import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import { useEffect, useState } from 'react'
import { NavLink, Outlet, useLocation, useNavigate } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

import { apiNoAuth } from '../services/api.service'

import HousekeepingLinks from '../components/housekeeping-links'

import { style } from '../style'

export const NAV_LINKS = [
    { text: 'MyBoomerang', link: '/' },
    { text: 'Marketplace', link: '/marketplace' },
    { text: 'Communities', link: '/communities' },
    { text: 'Contact', link: '/contact' },
]

export default function AppLayout() {
    const location = useLocation();

    return (
        <div className='relative flex flex-col min-h-screen bg-light min-w-screen'>
            <AppHeader></AppHeader>
            <main className='flex-1 w-full px-5 mx-auto max-w-none lg:max-w-5xl'>
                <Outlet></Outlet>
            </main>
            {location.pathname != "/" && <div className="flex justify-end my-3 mr-5">
                <a href="https://theboomerangtag.com/collections/boomerangtag" target="_blank" className="p-3 text-white bg-brand-500">Get BoomerangTag →</a>
            </div>}
            <AppFooter></AppFooter>
        </div>
    )
}

function AppHeader() {
    const navigate = useNavigate()
    const [open, setOpen] = useState(false)
    const [userType, setUserType] = useState(null)

    useEffect(() => {
        apiNoAuth(`${process.env.REACT_APP_API_URL}/app/check-user-type`).then((x) => setUserType(x.userType))
    }, [])

    return (
        <div className='sticky top-0 left-0 right-0 z-50 flex items-center justify-between px-6 py-2 bg-black shadow-md select-none'>

            <div className="flex items-center justify-between w-full mx-auto">
                {/* Brand */}
                <div className='flex items-center cursor-pointer' onClick={() => navigate('/')}>
                    <img src='/logo-dark.svg' width={96} height={96} alt='Boomerang' />
                </div>

                {/* Header Nav */}
                <div className='hidden md:block'>
                    <nav className='flex items-center space-x-6'>
                        {userType !== 'anonymous' && (
                            <>
                                {NAV_LINKS.map((d) => (
                                    <NavLink key={d.link} to={d.link} className='font-bold whitespace-nowrap text-neutral-100 hover:text-brand-500'>
                                        {d.text}
                                    </NavLink>
                                ))}
                            </>
                        )}
                        {userType === 'anonymous' ? (
                            <>
                                <NavLink to='/register' className={twMerge(style.button.classname, style.button._secondary.classname)}>
                                    Register For Boomerang
                                </NavLink>
                                <NavLink to='/login' className={twMerge(style.button.classname, style.button._secondary.classname)}>
                                    Log in
                                </NavLink>
                            </>
                        ) : (
                            <NavLink to='/logout' className={twMerge(style.button.classname, style.button._secondary.classname)}>
                                Log out
                            </NavLink>
                        )}
                    </nav>
                </div>
                {/* Sidebar Nav */}
                <div className='block md:hidden'>
                    <div className='cursor-pointer' onClick={() => setOpen(true)}>
                        <Bars3Icon className='w-8 h-8 text-white' />
                    </div>
                    {open && (
                        <div className='fixed top-0 bottom-0 left-0 right-0 bg-[rgba(0,0,0,0.2)]' onClick={() => setOpen(false)}>
                            <div className='fixed top-0 bottom-0 right-0 flex flex-col px-6 py-2 space-y-10 bg-white border-l shadow-2xl min-w-fit' onClick={(e) => e.stopPropagation()}>
                                <div className='flex justify-end w-full'>
                                    <div className='cursor-pointer h-[72px] flex' onClick={() => setOpen(false)}>
                                        <XMarkIcon className='w-8 h-8 my-auto' />
                                    </div>
                                </div>
                                <nav className='flex flex-col items-end px-5 space-y-4'>
                                    {NAV_LINKS.map((d) => (
                                        <NavLink onClick={() => setOpen(false)} key={d.link} to={d.link} className='font-bold text-neutral-700 hover:bg-brand-50'>
                                            {d.text}
                                        </NavLink>
                                    ))}

                                    {userType === 'anonymous' ? (
                                        <>
                                            <NavLink to='/register' className={twMerge(style.button.classname, style.button._secondary.classname)}>
                                                Sign Up
                                            </NavLink>
                                            <NavLink to='/login' className={twMerge(style.button.classname, style.button._secondary.classname)}>
                                                Log in
                                            </NavLink>
                                        </>
                                    ) : (
                                        <NavLink to='/logout' className={twMerge(style.button.classname, style.button._secondary.classname)}>
                                            Log out
                                        </NavLink>
                                    )}

                                </nav>
                            </div>
                        </div>
                    )}
                </div>
            </div>


        </div>
    )
}

function AppFooter() {
    return (
        <>
            <HousekeepingLinks />
        </>
    )
}
