import { useCallback, useEffect, useRef, useState } from 'react'
import { twMerge } from 'tailwind-merge'
// import QRCode from "react-qr-code"
import { QRCode } from 'react-qrcode-logo'
import { useNavigate, useParams } from 'react-router-dom'

import { api } from '../services/api.service'

import PageHeader from '../components/page-header'

import { BASE_URL } from '../app/home'
import { style } from '../style'
import { CheckboxField, SelectField, TextField } from './fields'
import ImageField from './fields/image-upload'
import FormWrapper from './form-wrapper'

export default function OwnershipForm(props) {
    const svgRef = useRef()
    const params = useParams()
    const [qrProps, setQrProps] = useState({})
    const navigate = useNavigate()
    let id = params.id ?? props.id

    const updateText = (text) => {
        const canvas = svgRef.current.canvas.current
        const context = canvas.getContext('2d')
        // context.fillStyle = "white"
        context.clearRect(0, 2300, 6000, 300)

        context.font = '200px Arial'
        context.fillStyle = 'black'
        context.textAlign = 'center'
        context.fillText(qrProps.text, 1250, 2450)
    }

    useEffect(() => {
        api(`${process.env.REACT_APP_API_URL}/admin/ownership/${id}`).then((x) => api(`${process.env.REACT_APP_API_URL}/admin/product/${x.product}`).then((x) => setQrProps({ ...x.defaultQrSettings })))
    }, [])

    useEffect(() => {
        try {
            updateText()
        } catch {}
    }, [qrProps])

    const downloadSVG = useCallback(() => {
        let url = svgRef.current.canvas.current.toDataURL('image/png')
        var link = document.createElement('a')
        link.download = 'filename.png'
        link.href = url
        link.click()
    }, [])

    return (
        <div>
            <PageHeader headline='Manage Ownership'></PageHeader>
            <FormWrapper onLoad={(data) => setQrProps({ ...data.defaultQrSettings })} url='admin/ownership' id={id} {...props} callback={() => navigate(-1)}>
                {(values, setValues) => (
                    <>
                        <TextField label='Batch Number' value={values.batchNumber} onChange={(val) => setValues({ ...values, batchNumber: val })} />
                        <CheckboxField label='Is Custom?' value={values.isCustom} onChange={(val) => setValues({ ...values, isCustom: val })} />
                        {!values.isCustom && <SelectField optionListName={'products'} label='Product' value={values.product} onChange={(val) => setValues({ ...values, product: val })}></SelectField>}
                        {values.isCustom && (
                            <>
                                <TextField label='Name' value={values.name} onChange={(val) => setValues({ ...values, name: val })}></TextField>
                                <ImageField label='Image' value={values.photo} onChange={(val) => setValues({ ...values, photo: val })} />

                                <TextField label='Description' value={values.description} onChange={(val) => setValues({ ...values, description: val })}></TextField>
                            </>
                        )}
                        <SelectField optionListName={'users'} label='Current Owner' value={values.currentOwner} onChange={(val) => setValues({ ...values, currentOwner: val })}></SelectField>

                        <CheckboxField label='Is Lost' value={values.isLost} onChange={(val) => setValues({ ...values, isLost: val })} />
                        <CheckboxField label='Is Found' value={values.isFound} onChange={(val) => setValues({ ...values, isFound: val })} />

                        <TextField label='Return Instructions' value={values.returnInstructions} onChange={(val) => setValues({ ...values, returnInstructions: val })}></TextField>

                        <div className='flex max-w-xl mx-auto mt-10 space-x-4'>
                            <div onClick={() => setQrProps({ ...qrProps, style: 'round' })} className={`${qrProps.style == 'round' ? 'bg-blue-500 text-white' : 'bg-white'} cursor-pointer shadow text-center flex-1 p-3 rounded-full`}>
                                Round
                            </div>
                            <div onClick={() => setQrProps({ ...qrProps, style: 'square' })} className={`${qrProps.style == 'square' ? 'bg-blue-500 text-white' : 'bg-white'} cursor-pointer shadow text-center flex-1 p-3 rounded-full`}>
                                Square
                            </div>
                        </div>

                        <TextField
                            className='max-w-xl mx-auto'
                            label='QR Code Text'
                            value={qrProps.text}
                            onChange={(val) => {
                                setQrProps({ ...qrProps, text: val })
                            }}
                        />
                        <CheckboxField
                            className='max-w-xl mx-auto'
                            label='Transparent Background'
                            value={qrProps.transparentBackground}
                            onChange={(val) => {
                                setQrProps({ ...qrProps, transparentBackground: val })
                            }}
                        />

                        <div className='max-w-xl mx-auto mt-5'>
                            <div>Logo</div>
                            <ImageUpload setImage={(val) => setQrProps({ ...qrProps, logo: val })} />
                        </div>

                        {id !== 'new' && (
                            <>
                                <div className='flex flex-col items-center mt-5'>
                                    <div className='hidden'>
                                        <QRCode bgColor={qrProps.transparentBackground ? '#FFFFFF00' : '#FFFFFF'} ref={svgRef} ecLevel='Q' size='2000' logoPaddingStyle={qrProps.style == 'round' ? 'circle' : 'squares'} logoPadding={2} removeQrCodeBehindLogo={true} value={`${BASE_URL}/ownership/${id}`} eyeRadius={qrProps.style == 'round' ? 10 : 0} qrStyle={qrProps.style == 'round' ? 'dots' : 'squares'} logoImage={qrProps.logo} quietZone={250} />
                                    </div>
                                    <button className={twMerge(style.button.classname, style.button._primary.classname, 'mx-auto mt-4')} type='button' onClick={() => downloadSVG()}>
                                        Download
                                    </button>
                                </div>
                            </>
                        )}
                    </>
                )}
            </FormWrapper>
        </div>
    )
}

const ImageUpload = ({ setImage }) => {
    const handleImageUpload = (event) => {
        const file = event.target.files[0]
        const reader = new FileReader()

        reader.onloadend = () => {
            setImage(reader.result)
        }

        if (file) {
            reader.readAsDataURL(file)
        }
    }

    return (
        <div>
            <input type='file' onChange={handleImageUpload} />
        </div>
    )
}
