import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

import { api } from '../services/api.service'

import AuxiliaryProductDetails from '../components/auxiliary-product-details'
import Card from '../components/card'
import ImageViewer from '../components/image-viewer'
import Loading from '../components/loading'

import { TextAreaField } from '../forms/fields'
import FormModal from '../forms/form-modal'

import RehomeConfirm from '../components/rehome-confirm'
import { style } from '../style'

export const BASE_URL = process.env.REACT_APP_URL

export default function Home() {
    const navigate = useNavigate()
    const [data, setData] = useState()
    const [markAsLostId, setMarkAsLostId] = useState()
    const [returnInstructions, setReturnInstructions] = useState()
    const [confirmDisown, setConfirmDisown] = useState(false)
    const [memberships, setMemberships] = useState()
    const [markAsLostIdForFoundItem, setMarkAsLostIdForFoundItem] = useState(false)

    const [userType, setUserType] = useState('user')

    useEffect(() => {
        api(`${process.env.REACT_APP_API_URL}/app`).then(({ ownerships, userType }) => {
            setData(ownerships)
            setUserType(userType)
        })
        api(`${process.env.REACT_APP_API_URL}/app/user-memberships`).then((x) => { setMemberships(x.memberships);  })
    }, [])

    const submitMarkAsLost = () => {
        api(`${process.env.REACT_APP_API_URL}/app/mark-as-lost/${markAsLostId}`, { returnInstructions }).then((x) => {
            api(`${process.env.REACT_APP_API_URL}/app`).then(({ ownerships }) => setData(ownerships))
            setReturnInstructions('')
            setMarkAsLostId(null)
            setMarkAsLostIdForFoundItem(null)
        })
    }

    const markAsReturned = (id) => {
        api(`${process.env.REACT_APP_API_URL}/app/mark-as-returned/${id}`).then((x) => {
            api(`${process.env.REACT_APP_API_URL}/app`).then(({ ownerships }) => setData(ownerships))
        })
    }

    const disown = (id) => {
        api(`${process.env.REACT_APP_API_URL}/app/disown-product/${id}`).then((x) => {
            api(`${process.env.REACT_APP_API_URL}/app`).then(({ ownerships }) => setData(ownerships))
        })
    }

    const getProductName = (item) => {
        if (!item.product && !item.name) {
            return 'Custom Item'
        } else if (!item.product) {
            return item.name
        } else return item.product.name
    }
    if (!data) return <Loading></Loading>

    return (
        <>

            <FormModal open={!!markAsLostId} setOpen={() => setMarkAsLostId(null)}>
                <div className='w-full max-w-6xl p-5 text-sm bg-white rounded-md shadow sm:text-base'>
                    <div className='mb-2 text-neutral-800'>Uh oh! You've lost it.</div>
                    <div className='mb-2 text-neutral-800'>Don't worry too much though, help is just around the corner. We feel it!</div>
                    <div className='mb-2 text-neutral-800'>Just provide some contact details such as your phone number and name for the finder here.</div>
                    <div className='mb-2 text-neutral-800'>If you don't leave any details, that's also OK, the finder will still be able to send an anonymous email to you with return details.</div>
                    <div className='mb-2 text-neutral-800'>Our fingers and toes are crossed for you!</div>
                    <div className='mb-2 text-neutral-800'>p.s. make sure Boomerang is an authorised sender, we wouldn't want it to end up in your junk mail.</div>
                    <TextAreaField value={returnInstructions} onChange={(val) => setReturnInstructions(val)} label='Return Instructions' />

                    <button className={twMerge(style.button.classname, style.button._secondary.classname, 'mt-2')} onClick={submitMarkAsLost}>
                        Submit
                    </button>
                </div>
            </FormModal>
            <FormModal open={!!markAsLostIdForFoundItem} setOpen={() => setMarkAsLostIdForFoundItem(null)}>
                <div className='w-full max-w-6xl p-5 text-sm bg-white rounded-md shadow sm:text-base'>
                    <div className='mb-2 text-neutral-800'>Hooray! Someone has found your item.</div>
                    <div className='mb-2 text-neutral-800'>To get your much loved belonging back as fast as possible, we suggest leaving your contact
                        number and name.</div>
                    <div className='mb-2 text-neutral-800'>If you don&#39;t leave any details, that&#39;s also OK, the finder will still be able to send an
                        anonymous email to you with return details.</div>
                    <div className='mb-2 text-neutral-800'>Our fingers and toes are crossed for you!</div>
                    <div className='mb-2 text-neutral-800'>p.s. be sure to check your junk mail we wouldn&#39;t want it to end up in there!</div>
                    <TextAreaField value={returnInstructions} onChange={(val) => setReturnInstructions(val)} label='Return Instructions' />

                    <button className={twMerge(style.button.classname, style.button._secondary.classname, 'mt-2')} onClick={submitMarkAsLost}>
                        Submit
                    </button>
                </div>
            </FormModal>
            <RehomeConfirm
                open={!!confirmDisown}
                confirm={() => {
                    disown(confirmDisown)
                    setConfirmDisown(false)
                }}
                cancel={() => {
                    setConfirmDisown(false)
                }}
            />
            <div className='mt-10 space-y-4'>

                {memberships && memberships.filter(x => x.status != "rejected").length > 0 && (
                    <div className='mb-10 '>
                        <div className='mt-10 text-3xl font-semibold text-neutral-800'>My Communities</div>
                        <div className='flex mt-5 space-x-10 whitespace-nowrap '>
                            {memberships.filter(x => x.status != "rejected")
                                .map((mem) => {
                                    return (
                                        <>
                                            <a className='inline-block w-auto p-3 font-bold transition-all rounded shadow bg-white/80 hover:shadow-xl hover:scale-105 text-neutral-700 hover:text-brand-500' href={`/organisation/${mem.organisation._id}`}>
                                                <div className='flex items-center'>

                                                    <ImageViewer className='w-12 h-12 mr-5 rounded flex-0' image={mem.organisation.image} />
                                                    <div>
                                                        {mem.isOrgAdmin && <div className="relative flex justify-end">
                                                            <h3 className='absolute px-3 py-1 font-medium text-center rounded-md -right-6 -top-8 text-brand-700 bg-brand-50 ring-1 ring-inset ring-brand-600/20'>Admin</h3>
                                                        </div>}
                                                        <p className='flex-1 mr-5'>{mem.organisation.name}</p>
                                                    </div>

                                                </div>
                                            </a>
                                        </>
                                    )
                                })}
                        </div>
                    </div>
                )}
                {data?.length > 0 &&
                    data?.sort((a, b) => {
                        const aPriority = a.isLost || a.isFound ? 1 : 0;
                        const bPriority = b.isLost || b.isFound ? 1 : 0;
                
                        if (aPriority > bPriority) return -1;
                        if (aPriority < bPriority) return 1;
                
                        const aName = a.name ? a.name : (a.product ? a.product.name : '');
                        const bName = b.name ? b.name : (b.product ? b.product.name : '');
                
                        return aName.localeCompare(bName);
                    }).map((x) => (
                        <Card className='p-5 sm:p-10'>
                            <div className='flex flex-col items-start justify-between space-y-2 sm:flex-row sm:items-center' onClick={() => navigate(`/ownership/${x._id}`)}>
                                <div>
                                    <h2 className='text-base text-neutral-600'>{x.product?.brand || ''}</h2>

                                    <h1 className='text-2xl font-black text-neutral-800'>{getProductName(x)}</h1>
                                </div>

                                <div>{getProductStatus(x)}</div>
                            </div>
                            <div className='flex flex-col justify-between m-4 space-y-4 sm:flex-row'>
                                <div className='flex flex-col justify-between text-center cursor-pointer shrink-0' onClick={() => navigate(`/ownership/${x._id}`)}>
                                    {(x.product?.photo || x.photo) && (
                                        <div>
                                            <ImageViewer image={x.product?.photo || x.photo} className='object-cover w-40 h-40 mx-auto rounded-md' />
                                        </div>
                                    )}
                                </div>
                                <AuxiliaryProductDetails product={x.product} />
                                {x.isCustom && <div>{x.description}</div>}
                                <div className={`flex ${x.product?.photo || x.photo ? 'flex-col space-y-2' : 'flex-row space-x-2'}`}>
                                    <div className='flex flex-col w-full space-y-4 sm:w-44'>
                                        {(x.isLost || x.isFound) ? (
                                            <button className={twMerge(style.button.classname, style.button._secondary.classname)} onClick={() => markAsReturned(x._id)}>
                                                Returned
                                            </button>
                                        ) : (
                                            <button className={twMerge(style.button.classname, style.button._secondary.classname)} onClick={() => setMarkAsLostId(x._id)}>
                                                Lost
                                            </button>
                                        )}
                                        {!x.isLost && !x.isFound && <button className={twMerge(style.button.classname, style.button._primary.classname)} onClick={() => navigate(`/create-listing/${x._id}`)}>
                                            List
                                        </button>}
                                        <button className={twMerge(style.button.classname, style.button._dark.classname)} onClick={() => setConfirmDisown(x._id)}>
                                            Rehome
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </Card>
                    ))}

                {!data?.length && <div className="mt-10 text-neutral-800">
                    <div className="text-2xl text-neutral-800">Welcome to Boomerang Community!</div>
                    <div className="mt-2 text-lg text-neutral-700 ">Great to have you with us.</div>
                    <div className="mt-2 text-lg text-neutral-700 ">Here you can Tag, Retrieve and Rehome your belongings.</div>
                    <div className="mt-2 text-lg text-neutral-700 ">Once tagged, simply use your phone camera to start registering your belongings here, keeping them safe and helping save our planet from mountains of lost property waste!</div>
                </div>}

                <div className="flex justify-center my-3 mr-5">
                    <a href="https://theboomerangtag.com/collections/boomerangtag" target="_blank" className="p-3 text-white bg-brand-500">Get BoomerangTag →</a>
                </div>

            </div>
        </>
    )
}

function getProductStatus(product) {
    if (product.isFound) {
        return <h3 className='px-3 py-1 font-medium text-center rounded-md text-brand-700 bg-brand-50 ring-1 ring-inset ring-brand-600/20'>Found - Click here to view details</h3>
    }
    if (product.isLost) {
        return <h3 className='px-3 py-1 font-medium text-center rounded-md text-brand-700 bg-brand-50 ring-1 ring-inset ring-brand-600/20'>Lost</h3>
    }
    return <h3 className='px-3 py-1 font-medium text-center rounded-md text-neutral-700 bg-neutral-50 ring-1 ring-inset ring-neutral-600/20'>Owned</h3>
}
