import { useState } from 'react'
import { twMerge } from 'tailwind-merge'

import { api } from '../services/api.service'

import { style } from '../style'
import { PhoneField, TextAreaField } from './fields'

export default function FindProductForm(props) {
    const [values, setValues] = useState({})
    const [error, setError] = useState("")
    return (
        <div>
            <TextAreaField placeholder='Phew! You found it! Reassure the owner you have their item and the best way to contact you so they can get it back.' labelClass='text-black' label='Leave a note' value={values.note} onChange={(val) => setValues({ ...values, note: val })}></TextAreaField>
            <PhoneField defaultCountry={'uk'} displayInitialValueAsLocalNumber value={values.phoneNumber} onChange={(val) => setValues({ ...values, phoneNumber: val })} />
            {/* <TextField placeholder='e.g. for UK: 07123456789 or +447123456789' labelClass='text-black' label='Phone Number' value={values.phoneNumber} onChange={(val) => setValues({ ...values, phoneNumber: val })}></TextField> */}
            <div className='text-xs text-neutral-500'>Your number will only be used to send you a text once the owner’s return details are available.</div>

            <br />
            <div className="my-2 text-sm text-red-500">{error}</div>
            <button
                className={twMerge(style.button.classname, style.button._secondary.classname)}
                onClick={() => {
                    if (!values.note){
                        setError("Whoops! Make sure you leave a note for the owner of this item so they know it's in safe hands and all is not lost.")
                        return
                    }
                    if (!values.phoneNumber){
                        setError("Whoops! Make sure you leave a number so you can receive a text once the owner's return details are available.")
                        return
                    }
                    api(`${process.env.REACT_APP_API_URL}/public/find-product/${props.id}`, values)
                    props.submitted()
                }}
                type='button'
            >
                Send
            </button>
        </div>
    )
}
