import { TrashIcon } from '@heroicons/react/24/outline'

export default function Button(props) {
    if (props.danger){
        return (
            <button {...props} className={`rounded border border-red-300 text-red-500 font-bold bg-white btn-hover px-3 py-1 ${props?.className ?? ''}`}>
                {props.children}
            </button>
        )
    }
    return (
        <button {...props} className={`rounded border border-slate-500 text-brand-500 font-bold bg-white btn-hover px-3 py-1 ${props?.className ?? ''}`}>
            {props.children}
        </button>
    )
}

const DIRECTIONS = {
    up: 'rotate(270 8 8)',
    down: 'rotate(90 8 8)',
    left: 'rotate(180 8 8)',
    right: undefined,
}
export function ArrowBtn({ onClick, direction = 'right', parentHover = false }) {
    return (
        <button onClick={onClick} className={`px-2 py-1 text-blue-600 bg-white border rounded-full border-slate-500 btn-hover w-min ${parentHover ? 'btn-parent-hover' : ''}`}>
            <svg viewBox='0 0 16 16' fill='none' height='16px'>
                <g transform={DIRECTIONS[direction]}>
                    <path d='M0 8 h16' stroke='green' />
                    <path d='M16 8, Q10 6, 8 0' stroke='green' />
                    <path d='M16 8, Q10 10, 8 16' stroke='green' />
                </g>
            </svg>
        </button>
    )
}

export function TickBtn({ onClick }) {
    return (
        <button onClick={onClick} className='px-2 py-1 border rounded-full border-slate-500 btn-hover w-min bg-emerald-600 hover:bg-emerald-800'>
            <svg viewBox='0 0 16 16' fill='none' height='16px'>
                <path d='M1 7, 7 14, 16 1' stroke='white' strokeWidth='2px' />
            </svg>
        </button>
    )
}

export function IconBtn({ icon, onClick }) {
    return (
        <button onClick={onClick} className='w-8 h-8 p-1 border rounded-full border-slate-500 btn-hover'>
            {icon}
        </button>
    )
}

export function TrashBtn({ onClick }) {
    return (
        <button onClick={onClick} className='px-0 transition bg-transparent text-slate-500 hover:text-rose-600'>
            <TrashIcon className='w-6 h-6' />
        </button>
    )
}
