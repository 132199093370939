import { twMerge } from 'tailwind-merge'

export default function AuxiliaryProductDetails({ product, className }) {
    return (
        product && (
            <div className={twMerge(className, 'p-2')}>
                {product?.size && <p><span className="font-bold">Size:</span> {product.size}</p>}
                {product?.category && <p><span className="font-bold">Category:</span> {product.category}</p>}
                {product?.colour && <p><span className="font-bold">Colour:</span> {product.colour}</p>}
                {product?.style && <p><span className="font-bold">Style:</span> {product.style}</p>}
                {product?.additionalDetails && (
                    <p className='max-w-xs'>
                        <span className="font-bold">Additional Details:</span> <br />
                        {product.additionalDetails}
                    </p>
                )}
            </div>
        )
    )
}
