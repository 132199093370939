import 'react-toastify/dist/ReactToastify.css'

import { useNavigate, useParams } from 'react-router'
import { CheckboxField, TextAreaField, TextField } from '../forms/fields'
import FormWrapper from '../forms/form-wrapper'

export default function RequestCommunity(props) {
    const params = useParams()
    const navigate = useNavigate();
    return (
        <div>
            <FormWrapper url='app/create-org-request' id='new' {...props} callback={() => navigate(-1)}>
                {(values, setValues) => (
                    <>
                    <div className="mb-3 text-3xl font-bold text-neutral-800">Create a community</div>
                    <div className="mb-5 text-lg text-neutral-700">Tell us a little bit about the community you would like to create</div>
                        <TextField label='Community Name' value={values?.name} onChange={(val) => setValues({ ...values, name: val })}></TextField>
                        <TextAreaField placeholder="Tell us who the community is for, what you would like people to list here, any other details that people should know!" label='Community Description' value={values?.description} onChange={(val) => setValues({ ...values, description: val })}></TextAreaField>
                        <CheckboxField label='Public Community' value={values?.isPublic} onChange={(val) => setValues({ ...values, isPublic: val })}></CheckboxField>
                       <div className="text-xs text-neutral-500">Do you want people to be able to discover this community on the Boomerang platform?</div>
                        <TextAreaField label='Notes' value={values?.notes} onChange={(val) => setValues({ ...values, notes: val })}></TextAreaField>
                    </>
                )}
            </FormWrapper>
        </div>
    )
}
