import { Dialog, Transition } from '@headlessui/react'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import { Fragment, useRef } from 'react'
import { twMerge } from 'tailwind-merge'

import { style } from '../style'

export default function RehomeConfirm(props) {
    const cancelButtonRef = useRef(null)

    return (
        <Transition.Root show={props.open} as={Fragment}>
            <Dialog as='div' className='relative z-40' initialFocus={cancelButtonRef} onClose={() => props.cancel()}>
                <Transition.Child as={Fragment} enter='ease-out duration-300' enterFrom='opacity-0' enterTo='opacity-100' leave='ease-in duration-200' leaveFrom='opacity-100' leaveTo='opacity-0'>
                    <div className='fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75' />
                </Transition.Child>

                <div className='fixed inset-0 z-10 overflow-y-auto'>
                    <div className='flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0'>
                        <Transition.Child as={Fragment} enter='ease-out duration-300' enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95' enterTo='opacity-100 translate-y-0 sm:scale-100' leave='ease-in duration-200' leaveFrom='opacity-100 translate-y-0 sm:scale-100' leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'>
                            <Dialog.Panel className='relative px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-lg sm:p-6'>
                                <div className='sm:flex sm:items-start'>
                                    <div className='flex items-center justify-center flex-shrink-0 w-12 h-12 mx-auto bg-red-100 rounded-full sm:mx-0 sm:h-10 sm:w-10'>
                                        <ExclamationTriangleIcon className='w-6 h-6 text-red-600' aria-hidden='true' />
                                    </div>
                                    <div className='mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left'>
                                        <Dialog.Title as='h3' className='text-lg font-medium leading-6 text-neutral-700'>
                                            Are You Sure you want to rehome this item?
                                        </Dialog.Title>
                                    </div>
                                </div>
                                <div className='mt-5 sm:mt-4 sm:flex sm:flex-row-reverse'>
                                    <button type='button' className={twMerge(style.button.classname, style.button._dark.classname, 'inline-flex justify-center w-full text-base sm:ml-3 sm:w-auto sm:text-sm')} onClick={() => props.confirm()}>
                                        Yes
                                    </button>
                                    <button type='button' className={twMerge(style.button.classname, style.button._positive.classname, 'inline-flex justify-center w-full mt-3 text-base sm:mt-0 sm:w-auto sm:text-sm')} onClick={() => props.cancel()} ref={cancelButtonRef}>
                                        No
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
