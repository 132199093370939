import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import PageHeader from '../components/page-header'
import { ImageUpload, TextAreaField, TextField } from './fields'
import FormWrapper from './form-wrapper'

export default function CustomOwnershipForm(props) {
    const params = useParams()
    const navigate = useNavigate()
    let id = params.id ?? props.id

    return (
        <div className='my-2'>
            <PageHeader headline='BoomerangTag'></PageHeader>
            <FormWrapper url={`app/ownership`} id={id} includeIdInPost={true} {...props} callback={() => navigate(-1)}>
                {(values, setValues) => (
                    <>
                        <TextField required label='Product' value={values?.name?.toLowerCase().includes("your item needs a name") ? "": values.name} onChange={(val) => setValues({ ...values, name: val })}></TextField>
                        <ImageUpload label='Upload a photo' value={values.photo} onChange={(val) => setValues({ ...values, photo: val })}></ImageUpload>
                        <TextAreaField label='Description' placeholder='Add a description of your item, you can include any distinguishing features or unique aspects of your item...' value={values.description} onChange={(val) => setValues({ ...values, description: val })} />
                    </>
                )}
            </FormWrapper>
        </div>
    )
}
